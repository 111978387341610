export const environment:any={
	production:false,
	apiServer:{
		url:'https://api.test.benditagrow.com'
	},
	webServer:{
		url:'https://test.benditagrow.com'
	},
	adminServer:{
		url:'https://admin.test.benditagrow.com'
	}
};




